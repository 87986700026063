import { useMemo } from 'react';
import { DailyLog } from '../../api/api';
import { helpers } from '../../utils/helpers';
import { IconStatus } from '../IconStatus/IconStatus';
import { ITableRow, TableSimple } from '../Table/TableSimple';
// import { useAxios } from '../../hooks/useAxios';

interface IDashboardExpandedProps {
    id: string | number;
    logs?: DailyLog[];
}
const DashboardExpandedList = ({ id, logs }: IDashboardExpandedProps) => {
    const { calculatePercentage } = helpers();

    const formattedTableData = useMemo(() => {
        const list: DailyLog[] = logs || [];
        const tData: ITableRow[] = [];

        list.forEach((x) => {
            const failedCount = x.countFailed || 0;
            const successCount = x.countSuccessful || 0;

            const successPercentage = calculatePercentage(
                successCount,
                failedCount + successCount
            );

            const failPercentage = calculatePercentage(
                failedCount,
                failedCount + successCount
            );

            const row: ITableRow = {
                id: x.rowKey as string,
                values: [
                    x.objectType,
                    x.eventType,
                    successPercentage,
                    failPercentage,
                    x.countSuccessful,
                    x.countFailed,
                    <IconStatus fail={failedCount > 0} badge={true} />,
                    null,
                ],
            };

            tData.push(row);
        });

        return tData;
    }, [logs]);

    return (
        <div>
            <TableSimple
                header={[]}
                rows={formattedTableData}
                styleClasses="arl-table__dashboard-items"
                loadingState="loaded"
            />
        </div>
    );
};

export default DashboardExpandedList;
