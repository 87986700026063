import React, { useState } from 'react';

export interface IOptionItem {
    label: string;
    value: string | number;
}
export interface ISelectList {
    options: IOptionItem[];
    callBack: (seelectedValue: string) => void;
    ariaLabel?: string;
    selectedIndex?: number;
    customClassName?: string;
    initialLabel?: string;
}

export const Select = ({
    options,
    callBack,
    ariaLabel,
    selectedIndex,
    customClassName,
    initialLabel,
}: ISelectList) => {
    const [selectedItem, setSelectedItem]: [string, (value: string) => void] =
        useState(options[0]?.label || '');

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedItem(event.target.value);
        callBack(event.target.value);
    };

    return (
        <select
            className={`form-select ${customClassName}`}
            onChange={handleChange}
        >
            {initialLabel && <option value="">{initialLabel}</option>}

            {options.map((x, index) => (
                <option key={index} value={x.value}>
                    {x.label}
                </option>
            ))}
        </select>
    );
};
