import './IconStatus.scss';

export interface IIconStatus {
    fail: boolean;
    badge?: boolean;
}

export const IconStatus = (props: IIconStatus) => {
    return (
        <span
            className={
                'arl-icon-status ' +
                (props.fail ? 'arl-icon-status--failed' : 'arl-icon-status--ok') +
                (props.badge ? ' badge' : '')
            }
        >
            {!props.badge ? '' : (props.badge && props.fail) ? 'Failed' : 'Succeeded'}
        </span>
    );
};
