import React from 'react';

export const Topdesk = () => {
    const title = 'topdesk';
    return (
        <main>
            <h2>{title}</h2>
        </main>
    );
};
