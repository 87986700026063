import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IOptionItem, Select } from '../components/Form/Select/Select';
import { IconStatus } from '../components/IconStatus/IconStatus';
import ReactDatePicker from 'react-datepicker';
import datePicker from '../images/date-picker.svg';
import { ITableRow, TableSimple } from '../components/Table/TableSimple';
import DateObject from 'react-date-object';
import DatePicker from 'react-datepicker';
import { useAxiosSwagger } from '../hooks/useAxiosSwagger';
import {
    EnvironmentClient,
    EnvironmentResponseDto,
    ObjectType,
    QueueLogClient,
    QueueLogMessageDto,
} from '../api/api';
import { loadingStateType } from './dashboard';
import { MessageBox } from '../components/MessageBox/MessageBox';
import { isDate } from 'util/types';

export const DashboardDetail = () => {
    const navigate = useNavigate();
    let { customerID, environment } = useParams();

    const tableConfig = [
        { id: 0, text: 'Type' },
        { id: 1, text: 'Id' },
        { id: 2, text: 'Action' },
        { id: 3, text: 'Parent' },
        { id: 4, text: 'Related' },
        { id: 5, text: 'Functional notification' },
        { id: 6, text: 'Date' },
        { id: 7, text: 'Status' },
    ];

    const now = new Date();
    const [startDate, setStartDate] = useState<Date | null>(
        new Date(now.setDate(now.getDate() - 7))
    );
    const [endDate, setEndDate] = useState<Date | null>(new Date());

    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState<loadingStateType>('init');
    const [data, setData] = useState<QueueLogMessageDto[]>([]);
    const [clientEnvironment, setClientEnvironment] = useState<
        EnvironmentResponseDto[]
    >([]);
    const [objectType, setObjectType] = useState<ObjectType | string>('');

    const uniqueObjectTypes = useMemo(() => {
        const typesOptions: IOptionItem[] = [];

        Object.keys(ObjectType).forEach((x) => {
            typesOptions.push({ label: x, value: x });
        });

        return typesOptions;
    }, []);

    const axios = useAxiosSwagger();

    const formatMessage = (message: string) => {
        const regex = /,/g;
        return message.replace(regex, ', ');
    };

    useEffect(() => {
        if (axios) {
            (async () => {
                try {
                    setLoadingState('loading');
                    const client = new QueueLogClient(undefined, axios);
                    let response;
                    if (objectType) {
                        response = await client.range2(
                            customerID as string,
                            environment as string,
                            startDate as Date,
                            endDate as Date,
                            objectType as ObjectType
                        );
                    } else {
                        response = await client.range(
                            customerID as string,
                            environment as string,
                            startDate as Date,
                            endDate as Date
                        );
                    }

                    setData(response);
                    setLoadingState('loaded');
                } catch (error) {
                    console.log(error);
                    setLoadingState('error');
                }
                setLoaded(true);
            })();
        }
    }, [
        startDate,
        endDate,
        customerID,
        environment,
        objectType,
        uniqueObjectTypes,
    ]);

    useEffect(() => {
        if (axios) {
            (async () => {
                try {
                    const client = new EnvironmentClient(undefined, axios);
                    const response = await client.environmentAll(
                        customerID as string
                    );

                    setClientEnvironment(response);
                } catch (error) {
                    console.log(error);
                }
                setLoaded(true);
            })();
        }
    }, [customerID, axios]);

    const formattedTableData = useMemo(() => {
        if (!data.length) {
            return [];
        }
        const list: QueueLogMessageDto[] = data || [];
        const tData: ITableRow[] = [];
        //.sort((a,b ) => (a.timestamp as Date) > (b.timestamp.as Date))
        list.forEach((x) => {
            const timestamp = new DateObject({
                date: new Date(x.timestamp),
            });

            console.log(typeof x.timestamp);
            // if(typeof x.timestamp.getMonth === 'function')
            {
                // const t = x.timestamp?.toISOString();
                // console.log(t);
                const row: ITableRow = {
                    id: x.rowKey as string,
                    values: [
                        x.objectType,
                        x.relationId,
                        <MessageBox
                            text={formatMessage(x.eventType ?? '')}
                            title={x.errorMessage ?? ''}
                        />,
                        x.additionalInfo1,
                        x.additionalInfo2,
                        x.errorFunctionalMessage ?? '',
                        // timestamp.format('dd-MM-YYYY HH:mm:ss'),
                        x.timestamp.substring(0, x.timestamp.indexOf('T')),
                        <IconStatus fail />,
                    ],
                };
                tData.push(row);
            }
        });
        return tData;
    }, [data]);

    const uniqueEnvironments = useMemo(() => {
        const options: IOptionItem[] = [];

        const list: EnvironmentResponseDto[] = clientEnvironment || [];
        list.forEach((x) => {
            options.push({ label: x.environment!, value: x.environment! });
        });

        return options;
    }, [clientEnvironment]);

    const handleObjectTypeSelect = (selectedValue: string) => {
        console.log('handleTypeSelect: ', selectedValue);
        setObjectType(selectedValue as ObjectType);
    };

    const handleEnvironmentSelect = (selectedValue: string) => {
        if (!selectedValue) {
            return;
        }
        navigate(`/dashboard/${customerID}/${selectedValue}`);
    };

    const handleNotificationSelect = (selectedValue: string) => {
        console.log('handleNotificationSelect: ', selectedValue);
    };

    const handleExportCSV = () => {
        console.log('handleExportCSV');
    };

    const handleDateChange = (range: any) => {
        const [startDate, endDate] = range;
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <main className="main-padding">
            <div className="row">
                <h2>Dashboard {customerID}</h2>
                <p className='dashboard-info'>Here's a quick glance at your environment's current performance metrics</p>
            </div>
            <div className="dashboard--detail row mb-4">
                <div className="col-12">
                    <form className="row mb-4">
                        <div className="col-auto">
                            <Select
                                initialLabel="Environment"
                                options={uniqueEnvironments}
                                callBack={handleEnvironmentSelect}
                                customClassName='dashboard shadow-none'
                            />
                        </div>
                        <div className="col-auto">
                            <Select
                                initialLabel="ObjectType"
                                options={uniqueObjectTypes}
                                callBack={handleObjectTypeSelect}
                                customClassName='dashboard shadow-none'
                            />
                        </div>
                        <div className="col-auto relative">
                            <img src={datePicker} className={'date-picker'} alt="date-picker" />
                            <ReactDatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat={'dd/MM/yyyy'}
                                onChange={handleDateChange}
                                className="form-control dashboard multi"
                                placeholderText="Select date"
                                isClearable={startDate !== null}
                            />
                        </div>
                        <div className="col">
                            <div className="row justify-content-end">
                                <div className="col-auto d-none">
                                    <Select
                                        options={[
                                            {
                                                label: 'All notifications',
                                                value: 0,
                                            },
                                            {
                                                label: 'Some notifications',
                                                value: 1,
                                            },
                                        ]}
                                        callBack={handleNotificationSelect}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="row">
                        <div className="col">
                            <TableSimple
                                header={tableConfig.map((x) => x.text)}
                                rows={formattedTableData}
                                styleClasses="arl-table__dashboard"
                                dataLoaded={loaded}
                                loadingState={loadingState}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
