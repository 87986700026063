export const helpers = () => {
    const calculatePercentage = (part: number, total: number) => {
        const percentage = (part / total) * 100;
        if (Number(percentage)) {
            return Math.round(percentage);
        }
        return 0;
    };

    return { calculatePercentage };
};
