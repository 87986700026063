import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Dashboard } from './routes/dashboard';
import { Topdesk } from './routes/topdesk';
import { DashboardDetail } from './routes/dashboardDetail';
import { getConfig } from './config';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    ...(config.scope ? { scope: config.scope } : null),
    redirectUri: window.location.origin,
};
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0Provider {...providerConfig}>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={<Dashboard />} />
                        <Route path="dashboard" element={<Dashboard />}></Route>
                        <Route
                            path="dashboard/:customerID/:environment"
                            element={<DashboardDetail />}
                        />
                        <Route path="topdesk" element={<Topdesk />} />
                        <Route
                            path="*"
                            element={
                                <main style={{ padding: '1rem' }}>
                                    <p>There's nothing here!</p>
                                </main>
                            }
                        />
                    </Route>
                </Routes>
            </Auth0Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
