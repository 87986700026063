import './AppHeader.scss';
import logo from '../../images/possibilit_logo.svg';
import arrowBarLeft from '../../images/arrow-bar-left.svg';
import arrowDegLeft from '../../images/arrow-deg-left.svg';
import verticalVector from '../../images/vertical-vector.svg';
import { Link, Navigate, redirect } from 'react-router-dom';
import { UserProfile } from '../UserProfile/UserProfile';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export const AppHeader = () => {
    const { isAuthenticated, logout, user } = useAuth0();
    const [isOnDetailPage, setIsOnDetailPage] = useState<boolean>(true);

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
    };

    return (
            <div className="row">
                <div className="col">
                    <header className="arl-header">
                        <div className="arl-header__logo">
                            <Link to={'/'}>
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        {isAuthenticated && (
                            <nav className="arl-header__nav">
                                <span className={'d-none'}> |{' '}</span>
                                <Link to="/topdesk" className={'d-none'}>topdesk</Link>
                            </nav>
                        )}

                        {isAuthenticated && (
                            <div className="arl-header__actions">
                                { isOnDetailPage &&
                                    <div className="arl-header__flex">
                                        <Link to="/dashboard" className='arl-header__dashboard-link'>
                                            <img src={arrowDegLeft} width={24} height={24} alt="arrow-90deg-left" />
                                            <span>Dashboard</span>
                                        </Link>
                                        <img src={verticalVector} width={24} height={24} alt="arrow-bar-left" />
                                    </div>
                                }
                                <div
                                    onClick={handleLogout}
                                    className='arl-header__logout-link'
                                    aria-label="Log Out"
                                >
                                    <img src={arrowBarLeft} width={24} height={24} alt="arrow-bar-left" />
                                    <div>Logout</div>
                                </div>
                            </div>
                        )}
                    </header>
                </div>
            </div>
    );
};
