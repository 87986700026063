import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

type AxiosContext = { axiosInstance?: AxiosInstance };
const initialContext: AxiosContext = { axiosInstance: undefined };
const AxiosReactContext = createContext<AxiosContext>(initialContext);

// 1 component to define an Axios Instance in a Context
export const AxiosProvider: React.FunctionComponent<{ children: ReactNode }> = (
    props
) => {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        if (getAccessTokenSilently) {
            (async () => {
                setToken(await getAccessTokenSilently());
            })();
        }
    }, [getAccessTokenSilently]);

    const contextValue: AxiosContext = useMemo(() => {
        let axiosInstance = axios.create({
            baseURL: 'https://pitopsconnector.azurewebsites.net',//'https://pitopsconnector.azurewebsites.net', // https://localhost:44334
            transformResponse: [],
        });

        axiosInstance.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${token}`;
        axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] =
            '*';
        axiosInstance.defaults.headers.common['Access-Control-Allow-Methods'] =
            'GET, POST, PATCH, PUT, DELETE, OPTIONS';
        axiosInstance.defaults.headers.common['Access-Control-Allow-Headers'] =
            'Origin, Content-Type, X-Auth-Token';

        axiosInstance.interceptors.response.use(
            (response: AxiosResponse<any>) => {
                return response;
            },
            (error) => {
                // const problemDetails: any = error.response.data;
                // console.log('problemDetails:', problemDetails);

                // switch (problemDetails.code) {
                //     case ApiErrorCode.EntityNotFound:
                //         toast.error('EntityNotFound!');
                //         break;
                //     case ApiErrorCode.InvalidStatusChange:
                //         toast.error(
                //             `Invalid Status Change! Allowed status: ${(
                //                 problemDetails.additionalDetails
                //                     .allowedStatus as string[]
                //             ).join(', ')}`
                //         );
                //         break;
                //     default:
                //     // Do nothing and let specific custom business exception handling.
                // }

                return Promise.reject(error);
            }
        );

        return { axiosInstance };
    }, [token]);

    return (
        <AxiosReactContext.Provider value={contextValue}>
            {props.children}
        </AxiosReactContext.Provider>
    );
};

// 1 custom hook to access the Axios Instance from the Context

export const useAxiosSwagger = () =>
    useContext(AxiosReactContext).axiosInstance;
