import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useState } from 'react';
import { ITableRow, ITableSimpleProps } from './TableSimple';

interface ITableExtendedProps extends ITableSimpleProps {
    children: ReactElement;
    // childElement?: ReactElement;
}

interface IExpandedTableRowProps {
    row: ITableRow;
    children: ReactElement;
}

const ExpandableTableRow = ({ row, children }: IExpandedTableRowProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <tbody className={expanded ? 'expanded' : 'not-expanded'}>
            <tr className="static-row" onClick={() => {setExpanded(!expanded)}}>
                {row.values.map((value, valueIndex: number) => (
                    <td key={valueIndex}>{value}</td>
                ))}
                {/* <td>
                    <button
                        className={'btn btn-link'}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={expanded ? 'angle-up' : 'angle-down'}
                            size={'lg'}
                        />
                    </button>
                </td> */}
            </tr>
            {expanded && (
                <tr className="expandable-row">
                    <td
                        colSpan={row.values.length}
                        className="expandable-area"
                    >
                        {children}
                    </td>
                </tr>
            )}
        </tbody>
    );
};

export const TableExpandable = ({
    rows,
    dataLoaded,
    styleClasses,
    header,
    children,
    loadingState,
}: ITableExtendedProps) => {
    return (
        <table className={`arl-table arl-table-expandable ${styleClasses}`}>
            <colgroup>
                {header.map((x, index: number) => (
                    <col key={index} className={`col--${index}`} />
                ))}
            </colgroup>

            <thead>
                <tr>
                    {header.map((x, index: number) => (
                        <th key={index}>{x}</th>
                    ))}
                </tr>
            </thead>
            {loadingState === 'loading' && (
                <tbody>
                    <tr>
                        <td colSpan={header.length}>
                            <div>
                                loading...{' '}
                                <FontAwesomeIcon icon="spinner" spin />
                            </div>
                        </td>
                    </tr>
                </tbody>
            )}
            {loadingState === 'loaded' && (
                <>
                    {rows.map((row, rowIndex: number) => (
                        <ExpandableTableRow key={rowIndex} row={row}>
                            {children.props.children.find(
                                (x: ReactElement) => x.key === row.id
                            )}
                        </ExpandableTableRow>
                    ))}
                </>
            )}
        </table>
    );
};
