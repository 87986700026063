import { DailyLog } from '../../api/api';
import { loadingStateType } from '../../routes/dashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

export interface ITableRow {
    id: number | string;
    values: (null | string | number | JSX.Element | undefined)[];
    childData?: DailyLog[];
    expanded?: boolean;
}
export interface ITableSimpleProps {
    header: string[];
    rows: ITableRow[];
    styleClasses?: string;
    dataLoaded?: boolean;
    loadingState?: loadingStateType;
}

export const TableSimple = (props: ITableSimpleProps) => {
    const colCount =
        props.header.length > 0
            ? props.header.length
            : props.rows.length > 0
            ? props.rows[0].values.length
            : 1;


    return (
        <table className={`arl-table arl-table-simple ${props.styleClasses}`}>
            {props.rows.length > 0 && (
                <colgroup>
                    {props.rows[0].values.map((value, valueIndex: number) => (
                        valueIndex < 7 && 
                        <col
                            key={valueIndex}
                            className={`col--${valueIndex}`}
                        />
                    ))}
                </colgroup>
            )}

            {props.header.length > 0 && (
                <thead>
                    <tr>
                        {props.header.map((x, index: number) => (
                            <th key={index}>{x}</th>
                        ))}
                    </tr>
                </thead>
            )}

            {props.loadingState === 'loading' && (
                <tbody>
                    <tr>
                        <td colSpan={colCount}>
                            <div>
                                loading...{' '}
                                <FontAwesomeIcon icon="spinner" spin />
                            </div>
                        </td>
                    </tr>
                </tbody>
            )}

            {props.loadingState === 'loaded' && props.rows.length === 0 && (
                <tbody>
                    <tr>
                        <td colSpan={colCount} className='no-logs'>no logs found...</td>
                    </tr>
                </tbody>
            )}

            {props.loadingState === 'loaded' && props.rows.length > 0 && (
                <tbody>
                    {props.rows.map((row, rowIndex: number) => (
                        <tr key={rowIndex}>
                            {row.values.map((value, valueIndex: number) => (
                                valueIndex < 8 && 
                                <td key={valueIndex}>{value}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            )}
        </table>
    );
};
