import { useAuth0, User } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';

import './App.scss';
import { AppHeader } from './components/AppHeader/AppHeader';
import { AxiosProvider } from './hooks/useAxiosSwagger';

// fontawesome
import { initFontAwesome } from './utils/initFontAwesome';
import { useEffect, useState } from 'react';
import { ButtonLogin } from './components/ButtonLogin/ButtonLogin';
initFontAwesome();

export const App = () => {
    const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({ redirectUri: window.location.origin })
        }
    }, [isLoading])

    return (
        <AxiosProvider>
            <div className="App">
                <AppHeader />
                <div className="container">
                    <div className="col">
                        {/* {(!isLoading && !isAuthenticated) && (
                            <div className='text-center login-container'>
                                <h3 className='mb-2'>Aanmelden</h3>
                                <div>
                                    <ButtonLogin></ButtonLogin>
                                </div>
                            </div>
                        )} */}
                        
                        {!isAuthenticated && isLoading && <b>Loading</b>}

                        {!isLoading && isAuthenticated && <Outlet />}

                        {error && <div>Oops... {error.message}</div>}
                    </div>
                </div>
                <footer></footer>
            </div>
        </AxiosProvider>
    );
};
