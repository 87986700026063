import React, { useEffect, useMemo, useState } from 'react';
import { IOptionItem, Select } from '../components/Form/Select/Select';
import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { DashboardClient, DashboardRequestDto, DashboardResponseDto, DashboardViewType } from '../api/api';
import { IconStatus } from '../components/IconStatus/IconStatus';
import DashboardExpandedList from '../components/Lists/DashboardExpandedList';
import { TableExpandable } from '../components/Table/TableExpandable';
import { ITableRow } from '../components/Table/TableSimple';
import { useAxiosSwagger } from '../hooks/useAxiosSwagger';
import { helpers } from '../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import datePicker from '../images/date-picker.svg';
import { useAuth0 } from '@auth0/auth0-react';
// import { handleError } from '../utils/handleError';
export type loadingStateType = 'init' | 'loading' | 'loaded' | 'error';
// ToDO: why does this component render twice???
export const Dashboard = () => {
    const { isLoading, isAuthenticated } = useAuth0();
    
    const title = 'Dashboard';
    const tableConfig = [
        { id: 0, text: 'Token' },
        { id: 1, text: 'Name' },
        { id: 2, text: '% Succes' },
        { id: 3, text: '% Failure' },
        { id: 4, text: '# Succes' },
        { id: 5, text: '# Failure' },
        { id: 6, text: 'Status' },
    ];

    const { calculatePercentage } = helpers();

    const now = new Date();
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date());


    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState<loadingStateType>('init');
    const [data, setData] = useState<DashboardResponseDto[]>([]);
    const axios = useAxiosSwagger();

    const [viewType, setViewType] = useState<DashboardViewType | string>('');

    const uniqueViewTypes = useMemo(() => {
        const typesOptions: IOptionItem[] = [];

        Object.keys(DashboardViewType).forEach((x) => {
            typesOptions.push({ label: x, value: x });
        });

        return typesOptions;
    }, []);

    const handleViewTypeSelect = (selectedValue: string) => {
        console.log('handleViewTypeSelect: ', selectedValue);
        setViewType(selectedValue as DashboardViewType);
    };

    // const handleStartDateSelect = (selectedValue: Date) => {
    //     console.log('handleStartDateSelect: ', selectedValue);
    //     setStartDate(selectedValue as Date);
    // };

    // const h = handleError;

    useEffect(() => {
        if (axios) {
            (async () => {
                try {
                    setLoadingState('loading');
                    let req : DashboardRequestDto = {
                        viewType: DashboardViewType.Prod
                    };

                    if (viewType) {
                        req.viewType = viewType as DashboardViewType;
                    }

                    if (startDate) {
                        req.logsDate = startDate as Date;
                    }

                    const client = new DashboardClient(undefined, axios);
                    let response;
                    if (isAuthenticated) {
                        response = await client.dashboard(req);
                        setData(response);
                    }

                    setLoadingState('loaded');
                } catch (error) {
                    console.log(error);
                    setLoadingState('error');
                }
                setLoaded(true);
            })();
        }
    }, [axios, startDate, viewType, uniqueViewTypes]); //endDate

    const formattedTableData = useMemo(() => {
        const list: DashboardResponseDto[] = data || [];
        const tData: ITableRow[] = [];
        list.forEach((x) => {
            const dailyLogs = x.dailyLogs || [];

            const succesCount = dailyLogs.reduce(
                (total, next) => total + (next.countSuccessful || 0),
                0
            );
            const failCount = dailyLogs.reduce(
                (total, next) => total + (next.countFailed || 0),
                0
            );

            const succesPct = calculatePercentage(
                succesCount,
                succesCount + failCount
            );

            const failPct = calculatePercentage(
                failCount,
                succesCount + failCount
            );

            const row: ITableRow = {
                id: x.customerName + '-' + x.customerEnvironment,
                values: [
                    <IconStatus fail={x.tokenOk != true} />,
                    <Link
                        to={`/dashboard/${x.customerName}/${x.customerEnvironment}`}
                    >
                            {x.customerName} {x.customerEnvironment}
                    </Link>,
                    succesPct,
                    failPct,
                    succesCount,
                    failCount,
                    <IconStatus fail={failCount > 0} badge={true} />,
                ],
                childData: dailyLogs,
            };
            tData.push(row);
        });
        return tData;
    }, [data]);

    return (
        <main className="main-padding">
            <div className="row">
                <h2>{title}</h2>
                <p className='dashboard-info'>Here's a quick glance at your environment's current performance metrics</p>
            </div>
            <div className="dashboard row mb-4">
                <div className="col-auto">
                    <Select
                        initialLabel="Environment type"
                        options={uniqueViewTypes}
                        callBack={handleViewTypeSelect}
                        customClassName='dashboard shadow-none'
                    />
                </div>
                <div className="col-auto">
                    <div className="float-end relative">
                        <img src={datePicker} className={'date-picker'} alt="date-picker" />
                        <ReactDatePicker
                            selected={startDate}
                            openToDate={new Date()}
                            onChange={(date: Date) => setStartDate(date)}
                            className="form-control dashboard"
                            dateFormat={'dd/MM/yyyy'}
                            placeholderText="Select date"
                            maxDate={new Date()}
                            isClearable={startDate !== null}
                        />
                    </div>
                </div>
            </div>
            
            <div className="row">
            </div>

            <div className="row">
                <div className="col">
                    <TableExpandable
                        header={tableConfig.map((x) => x.text)}
                        rows={formattedTableData}
                        styleClasses="arl-table__dashboard"
                        dataLoaded={loaded}
                        loadingState={loadingState}
                    >
                        <>
                            {formattedTableData.map((x) => {
                                return (
                                    <DashboardExpandedList
                                        key={x.id}
                                        id={x.id}
                                        logs={x.childData}
                                    />
                                );
                            })}
                        </>
                    </TableExpandable>
                </div>
            </div>
        </main>
    );
};
