import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faPowerOff,
    faUser,
    faCircleInfo,
    faFileCsv,
    faCircleXmark,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';

export const initFontAwesome = () => {
    library.add(faAngleRight);
    library.add(faAngleDown);
    library.add(faAngleUp);
    library.add(faUser);
    library.add(faPowerOff);
    library.add(faCircleInfo);
    library.add(faFileCsv);
    library.add(faCircleXmark);
    library.add(faSpinner);
};
